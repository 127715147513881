import { Container, Dialog, IconButton, Stack, Typography } from '@mui/material';
import SoftBox from './StyledComponents/SoftBox';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
	handleClose?: () => void;

};

export const Terms = (props: Props) => {
	return (
			<SoftBox p={1}>

				<Typography>
					<Stack direction="row">
					<h1>Salgsvilkår for Polarshow.no</h1>
					<IconButton  onClick={props?.handleClose}>
						<CloseIcon />
					</IconButton>
					</Stack>
					<ol>
						<li>
							<h2>Parter</h2>
							<p>
								Disse salgsvilkårene regulerer avtalen mellom Polarshow.no (heretter kalt "selger") og
								kjøperen (heretter kalt "kunden") for salg av billetter/påmelding/klær til revy på Polarshow.no. Ved å
								fullføre en bestilling på Polarshow.no, godtar kunden disse salgsvilkårene.
							</p>
						</li>
						<li>
							<h2>Betaling</h2>
							<p>
								Polarshow.no godtar kun betaling gjennom Vipps. Betalingen skal fullføres umiddelbart
								ved bestilling av billetter. Ved betaling vil kunden motta en bekreftelse på
								bestillingen via SMS eller e-post.
							</p>
						</li>
						<li>
							<h2>Levering</h2>
							<p>
								Etter betaling vil billetten(e) være tilgjengelig i Polarshow.no sitt system. Kunden vil
								motta en bekreftelse på e-post og/eller SMS med billetten(e). Selger er ikke ansvarlig
								for eventuelle tekniske problemer som kan hindre levering av billetter.
							</p>
						</li>
						<li>
							<h2>Angrerett</h2>
							<p>
								Billettene/Påmelding/Annet salg på Polarshow.no er ikke refunderbare og kan ikke byttes mot andre billetter
								eller varer. Kunden kan ikke angre på kjøpet etter at billettene er kjøpt og betalt for.
							</p>
						</li>
						<li>
							<h2>Retur</h2>
							<p>Polarshow.no aksepterer ikke retur av billetter eller varer</p>
						</li>
						<li>
							<h2>Reklamasjonshåndtering</h2>
							<p>
								Dersom kunden mener det er feil på billettene/varene, eller de ikke fungerer, må kunden ta
								kontakt med Polarshow.no umiddelbart via e-post til{' '}
								<a href="mailto:vegar.wang@gmail.com">kundeservice</a>. Reklamasjoner vil bli behandlet
								så raskt som mulig.
							</p>
						</li>
						<li>
							<h2>Konfliktløsning</h2>
							<p>
								Dersom det skulle oppstå en tvist mellom selger og kunden, skal partene først forsøke å
								løse tvisten i minnelighet. Dersom dette ikke er mulig, kan tvisten bringes inn for de
								ordinære domstolene etter norsk rett.
							</p>
						</li>
					</ol>
					<p>
						Disse salgsvilkårene kan endres av Polarshow.no uten varsel til kunden. Kunden oppfordres til å
						lese salgsvilkårene nøye før de fullfører en bestilling på Polarshow.no.
					</p>
				</Typography>
			</SoftBox>
	);
};

export default Terms;
