import CloseIcon from "@mui/icons-material/Close";
import {
	Alert,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Container,
	Divider,
	FormControl,
	Grid,
	IconButton,
	LinearProgress,
	MenuItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SoftBox from "./StyledComponents/SoftBox";
import { initiatePayment, useEmulators, useTestMerchant } from "./firebase";
import { useCollection } from "./hooks/useFirestoreCollection";
import {
	CartItem,
	Item,
	Parent,
	Participant,
	RegistrationData,
} from "./types/types";

type Props = {};

const groupNames = [
	"Multimedia",
	"Konsept",
	"Teknisk",
	"Catering",
	"Kosebamse",
	"Security",
	"Aktør",
	"Band",
];

const classNames = [
	"8Ø1",
	"8Ø2",
	"8Ø3",
	"8V1",
	"8V2",
	"8V3",
	"9Ø1",
	"9Ø2",
	"9Ø3",
	"9V1",
	"9V2",
	"9V3",
	"10Ø1",
	"10Ø2",
	"10Ø3",
	"10V1",
	"10V2",
	"10V3",
];

export const Register = (props: Props) => {
	//const [numTickets, setNumTickets] = React.useState(1);
	const navigate = useNavigate();
	//const [mobileNumber, setMobileNumber] = React.useState("46770660");
	const [inProgress, setInProgress] = React.useState(false);
	const [error, setError] = React.useState("");
	const { docs: storeItems, loading } = useCollection<Item>("store");
	const [reg, setReg] = React.useState<Partial<Participant>>({
		className: classNames[0],
	});
	const [groupSelection1, setGroupSelection1] = React.useState<string>(
		groupNames[0]
	);
	const [groupSelection2, setGroupSelection2] = React.useState<string>(
		groupNames[1]
	);
	const [parent1, setParent1] = React.useState<Parent>({
		name: "",
		phone: "",
	});
	const [parent2, setParent2] = React.useState<Parent>({
		name: "",
		phone: "",
	});

	const [cart, setCart] = React.useState<CartItem[]>([]);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		console.log("Submitting");
		e.preventDefault();
		if (groupSelection1 === groupSelection2) {
			setError("Velg to forskjellige grupper.");
			return;
		}
		handleClickPay();
	};

	const updateCart = (cartItem: CartItem, op: "add" | "remove") => {
		console.log("Updating cart", cartItem, op);
		if (op === "add") {
			// find existing item if any and update it, otherwise add
			const existing = cart.find((i) => i.item.id === cartItem.item.id);
			if (existing) {
				existing.size = cartItem.size;
				setCart([...cart]);
			} else {
				setCart([...cart, cartItem]);
			}
		} else {
			setCart(cart.filter((i) => i.item.id !== cartItem.item.id));
		}
	};

	const handleClickPay = async () => {
		setInProgress(true);
		const cleanCart = cart.map((i) => {
			const { ref, ...rest } = i.item as any;
			i.item = rest;
			return i;
		});

		console.log("Contents of cart on Pay", cleanCart);

		try {
			const result: any = await initiatePayment({
				cart: cleanCart,
				registrationData: {
					participant: reg,
					parent1,
					parent2,
					group1Selection: groupSelection1,
					group2Selection: groupSelection2,
				} as RegistrationData,
				mobileNumber: parent1.phone,
				fallbackPrefix: `${window.location.origin}/ProcessPayment`,
				testMerchant: useTestMerchant,
				useLocalCallback: useEmulators,
			});

			const response = result.data;

			if (response.code !== "OK") {
				// handle error
				console.log("Error: ", result);
				setError("Error: " + result.data.message);
				return;
			} else {
				// Redirect to Vipps payment page
				//setRedirectUrl(result.data.redirectUrl);
				window.location.replace(response.redirectUrl);
				// use router and navigate to redirectUrl
			}
		} catch (error) {
			console.error("Error: ", error);
			setError("Error: " + error);
		} finally {
			setInProgress(false);
		}
	};

	if (loading) return <CircularProgress />;

	if (storeItems.length === 0)
		return (
			<Typography variant="h4">
				Ingen registreringer tilgjengelig
			</Typography>
		);

	// sort store items so that mandatory items are first
	storeItems.sort((a, b) => {
		if (a.mandatory && !b.mandatory) return -1;
		if (b.mandatory && !a.mandatory) return 1;
		return 0;
	});

	console.log("reg", reg);
	console.log("cart", cart);

	return (
		<Container>
			<SoftBox p={1} minWidth="250px">
				<Stack direction="row" justifyContent="space-between">
					<Typography variant="h5">Registrering</Typography>
					<IconButton onClick={() => navigate("/")}>
						<CloseIcon />
					</IconButton>
				</Stack>

				<Stack direction="row" display="flex" gap={1}>
					{/*<Box display="flex">
						<InfoOutlinedIcon fontSize="small" />
	</Box> */}
					<Box display="flex">
						<Box>
							<Typography variant="body1" color="secondary">
								En registrering per elev.
							</Typography>

							<Typography variant="body1" color="grey">
								Informasjonen som samles ved registrering vil
								kun være tilgjengelig for arrangørene av
								Polarshow.
								<br /> All data slettes 8.4.2025.
							</Typography>
						</Box>
					</Box>
				</Stack>

				<Stack m={1} gap={3} alignItems="center">
					<form onSubmit={handleSubmit} autoComplete="false">
						<FormControl>
							<Divider textAlign="left">
								<Typography variant="h6">Eleven</Typography>
							</Divider>
							<Grid container spacing={2} p={2}>
								<Grid item xs={12} sm={6}>
									<TextField
										id="name"
										required
										fullWidth
										label="Fornavn og etternavn"
										helperText="Fornavn og etternavn. Vil bli trykket i programmet"
										value={reg?.name}
										variant="outlined"
										onChange={(e) =>
											setReg({
												...reg,
												name: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="phone"
										required
										label="Telefonnummer"
										value={reg?.phone}
										variant="outlined"
										onChange={(e) =>
											e.target.value.length < 9 &&
											/^[0-9]*$/.test(e.target.value) &&
											setReg({
												...reg,
												phone: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										select
										id="klasse"
										value={reg?.className}
										variant="outlined"
										label="Klasse"
										onChange={(e) =>
											setReg({
												...reg,
												className: e.target.value,
											})
										}
									>
										{classNames.map((klasse) => (
											<MenuItem value={klasse}>
												{klasse}
											</MenuItem>
										))}
									</TextField>
								</Grid>
								<Grid item xs={12} />
								<Grid item xs={6} sm={6}>
									<TextField
										select
										id="groupSelection1"
										value={groupSelection1}
										variant="outlined"
										label="Gruppe førstevalg"
										error={
											groupSelection1 === groupSelection2
										}
										helperText={
											groupSelection1 === groupSelection2
												? "Gruppene må være forskjellige"
												: ""
										}
										onChange={(e) =>
											setGroupSelection1(e.target.value)
										}
									>
										{groupNames.map((group) => (
											<MenuItem value={group} key={group}>
												{group}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								<Grid item xs={6} sm={6}>
									<TextField
										select
										id="groupSelection2"
										value={groupSelection2}
										variant="outlined"
										label="Gruppe andrevalg"
										error={
											groupSelection1 === groupSelection2
										}
										helperText={
											groupSelection1 === groupSelection2
												? "Gruppene må være forskjellige"
												: ""
										}
										onChange={(e) =>
											setGroupSelection2(e.target.value)
										}
									>
										{groupNames.map((group) => (
											<MenuItem value={group} key={group}>
												{group}
											</MenuItem>
										))}
									</TextField>
								</Grid>

								<Grid item xs={12}>
									<Divider textAlign="left">
										<Typography variant="h6">
											Foresatte
										</Typography>
									</Divider>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										id="name"
										fullWidth
										required
										label="Foresatt 1"
										value={parent1?.name}
										variant="outlined"
										onChange={(e) =>
											setParent1({
												...parent1,
												name: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="phone"
										label="Telefonnummer"
										helperText="Brukes til betaling med Vipps"
										required
										value={parent1?.phone}
										variant="outlined"
										onChange={(e) =>
											e.target.value.length < 9 &&
											/^[0-9]*$/.test(e.target.value) &&
											setParent1({
												...parent1,
												phone: e.target.value,
											})
										}
									/>
								</Grid>

								<Grid item xs={12} sm={6}>
									<TextField
										id="name"
										fullWidth
										label="Foresatt 2"
										value={parent2?.name}
										variant="outlined"
										onChange={(e) =>
											setParent2({
												...parent2,
												name: e.target.value,
											})
										}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<TextField
										id="phone"
										label="Telefonnummer"
										value={parent2?.phone}
										variant="outlined"
										// Limit to 8 digits and only numbers
										onChange={(e) =>
											e.target.value.length < 9 &&
											/^[0-9]*$/.test(e.target.value) &&
											setParent2({
												...parent2,
												phone: e.target.value,
											})
										}
									/>
								</Grid>

								<Grid item xs={12}>
									<Divider textAlign="left">
										<Typography variant="h6">
											Mat & Merch
										</Typography>
									</Divider>
								</Grid>

								{storeItems.map((item) => (
									<CartRow
										item={item}
										key={item.id}
										updateCart={updateCart}
									/>
								))}
								<CartTotal
									cart={[
										...cart,
										...storeItems
											.filter((i) => i.mandatory)
											.map((i) => {
												return { item: i } as CartItem;
											}),
									]}
								/>
								<Grid item xs={12}>
									<Alert severity="info">
										Ikke økonomi til kjøp av klær? Foreldre
										kan ta kontakt med voksenstyret/Katrine
										strøm, telefon 911 89 795,{" "}
										<a href="mailto:katma.teater@gmail.com">
											mail
										</a>{" "}
									</Alert>
								</Grid>
							</Grid>

							{/*<Box sx={{ p: 2 }}>
								<TextField
									required
									id="mobilePhone"
									label="Telefonnummer"
									value={mobileNumber}
									onChange={(e) =>
										setMobileNumber(e.target.value)
									}
								/>
								</Box>*/}
							<Typography>{error}</Typography>

							<Box
								sx={{
									m: 1,
									position: "relative",
									display: "flex",
									justifyContent: "center",
								}}
							>
								<Button
									disabled={inProgress}
									//onClick={handleClickPay}
									type="submit"
								>
									<img
										src="/images/paywithvipps.svg"
										alt="Betal med Vipps"
									/>
								</Button>
							</Box>

							{inProgress && <LinearProgress />}
						</FormControl>
					</form>

					{/*<Button onClick={handleClickPay} variant="contained">
						Betal med Vipps
					</Button> */}
				</Stack>
			</SoftBox>
		</Container>
	);
};

const CartTotal: React.FC<{ cart: CartItem[] }> = ({ cart }) => {
	const total = cart.reduce((acc, item) => acc + item.item.price, 0);
	const theme = useTheme();
	return (
		<>
			<Divider />
			<Stack
				p={2}
				m={2}
				direction="row"
				width="100%"
				alignItems="center"
				justifyContent="space-between"
				bgcolor={theme.palette.mode === "dark" ? grey[700] : grey[200]}
			>
				<Box>Totalt:</Box> <Box>{total} kr</Box>
			</Stack>
		</>
	);
};

const CartRow: React.FC<{
	item: Item;
	updateCart: (item: CartItem, op: "add" | "remove") => void;
}> = ({ item, updateCart }) => {
	const [cartItem, setCartItem] = React.useState<CartItem>({
		item,
		size: item?.variants?.size[0],
	});
	const [selected, setSelected] = React.useState(
		item.mandatory ? true : false
	);

	useEffect(() => {
		if (item.mandatory) return;
		if (selected) {
			updateCart(cartItem, "add");
		} else {
			updateCart(cartItem, "remove");
		}
	}, [selected, cartItem]);

	return (
		<Stack
			direction="row"
			width="100%"
			alignItems="center"
			justifyContent="space-between"
		>
			<Stack direction="row" gap={2} alignItems="center" display="flex">
				<Checkbox
					value={selected}
					checked={selected}
					disabled={item.mandatory}
					onChange={(e, checked) => setSelected(checked)}
				/>
				{item.name}
				{item.type === "merch" && (
					<TextField
						select
						value={cartItem.size}
						variant="standard"
						inputProps={{ disableUnderline: true }}
						onChange={(e) => {
							setCartItem({ ...cartItem, size: e.target.value });
							setSelected(true);
						}}
					>
						{item.variants.size.map((size) => (
							<MenuItem value={size}>{size}</MenuItem>
						))}
					</TextField>
				)}
			</Stack>
			{item.price} kr
		</Stack>
	);
};

export default Register;
