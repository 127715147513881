import { collection, doc, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db, useTestMerchant } from '../firebase';
import { captureOrderPoll } from '../firebase';

// Order interface
export interface Order {
	id: string;
	status: 'CAPTURED' | 'INITIATED' | 'CANCELLED' | 'FAILED' | 'RESERVED';
	amout: number;
	mobileNumber: string;
}

const useOrder = (orderId: string | undefined) => {
	const [order, setOrder] = useState<Order | null>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [checkAgain, setCheckAgain] = useState(new Date());

	useEffect(() => {
		const captureOrder = async (order: any) => {
			console.log('Running captureOrder', order?.status);
			if (!order) return;
			// Make sure order is in a state where we can capture it
			if (!['INITIATED', 'RESERVED'].includes(order.status)) return;
			// Poll for order status
			captureOrderPoll({ orderId, testMerchant: useTestMerchant });
			//setTimeout(() => setCheckAgain(new Date()), 2000);
		};

		if (order === null) return;

		console.log('Order updated', order);

		if (!['CAPTURED', 'CANCELLED'].includes(order.status)) setTimeout(() => captureOrder(order), 5000);
	}, [order, orderId, checkAgain]);

	useEffect(() => {
		if (!orderId) return;
		console.log('Looking up order ', orderId);
		// Fetch order document from firestore
		const unsubscribe = onSnapshot(doc(db, 'orders', orderId), { includeMetadataChanges: true }, (doc) => {
			if (doc.exists()) {
				setOrder({ id: doc.id, ...doc.data() } as Order);
			} else {
				console.log('No such document!');
			}
			setIsLoading(false);
		});

		return unsubscribe;
	}, [orderId]);

	return { order, isLoading };
};

export default useOrder;
