import {
	Box,
	Container,
	CssBaseline,
	Dialog,
	LinearProgress,
	Link,
	Stack,
	ThemeProvider,
	createTheme,
	styled,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import no_nb from "dayjs/locale/nb";
import React, { Suspense } from "react";
import { Route, Routes, Link as reactLink } from "react-router-dom";
import ProcessPayment from "./ProcessPayment";
import Register from "./Register";
import ShowsFrontPage from "./ShowsFrontpage";
import Terms from "./Terms";
import { Auth } from "firebase-admin/lib/auth/auth";
import { AuthProvider } from "./contexts/auth-context";
//import AdminHome from './AdminHome';
const AdminHome = React.lazy(() => import("./AdminHome"));

const darkTheme = createTheme({
	palette: {
		mode: "dark",
	},
});

const loader = (elem: JSX.Element) => (
	<Suspense
		fallback={
			<Container>
				<LinearProgress />
			</Container>
		}
	>
		{elem}
	</Suspense>
);

export const ScrollFlexBox = styled(Box)(({ theme }) => ({
	paddingTop: 0,
	//flexDirection: 'column',
	display: "flex",
	flex: "1 1 auto", // Grow to fill space, allow shrink, auto basis
	overflowY: "auto", // Add scroll for overflow
}));

dayjs.locale(no_nb);

function App() {
	const [showTerms, setShowTerms] = React.useState(false);

	return (
		<ThemeProvider theme={darkTheme}>
			{/*<AuthProvider LoadingPage={<Box>Loading</Box>} SignInPage={<Box>Sign In</Box>}> */}
			<CssBaseline />
			<Stack
				id="main app stack"
				sx={{
					backgroundImage: "url(/images/background4.jpg)",
					backgroundSize: "cover",
					backgroundPosition: "center",
					height: "100.1vh",
					display: "flex",
				}}
			>
				{/* ------ App header ------ */}

				<Box
					id="app-header"
					//flexGrow="1 0 auto"
					display="flex"
					sx={{
						flex: "0 1 auto", // Do not grow, allow shrink, auto basis
						// Additional styles for the menu bar
						//mt: lConst.t,

						p: 1,
						backgroundColor: "rgba(0, 0, 0, 0.4)",
						boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.9)",
					}}
				>
					<Stack direction="row">
						<Typography variant="h5" sx={{ color: "white" }}>
							<Link
								underline="hover"
								variant="inherit"
								color="inherit"
								to="/"
								component={reactLink}
							>
								Polarshow
							</Link>
						</Typography>
					</Stack>
				</Box>
				{/* ------ App content ------ */}

				<ScrollFlexBox
					display="flex"
					height="100%"
					justifyContent="center"
				>
					<Routes>
						<Route path="/terms" element={<Terms />} />
						<Route path="/register" element={<Register />} />
						<Route path="/registrer" element={<Register />} />
						<Route path="/" element={<ShowsFrontPage />} />
						<Route
							path="/admin_ps2025"
							element={loader(<AdminHome />)}
						/>
						<Route
							path="/processPayment/:orderId"
							element={<ProcessPayment />}
						/>
					</Routes>
				</ScrollFlexBox>

				{/* ------ App footer ------ */}
				<Box
					sx={{
						flex: "0 1 auto",
					}}
				>
					<Typography color="white" variant="body1">
						<Stack
							sx={{
								p: 1,
								backgroundColor: "rgba(0,0,0, 0.4)",
							}}
							direction="row"
							justifyContent="space-evenly"
							alignItems="center"
						>
							<Box>
								<Link
									//to="/terms"
									//component={reactLink}
									underline="hover"
									variant="inherit"
									color="inherit"
									onClick={() => setShowTerms(true)}
								>
									Salgsvilkår
								</Link>
							</Box>
							<Box>
								<Link
									to="mailto:vegar.wang@gmail.com"
									component={reactLink}
									underline="hover"
									variant="inherit"
									color="inherit"
								>
									Kontakt oss
								</Link>
							</Box>
						</Stack>
					</Typography>
					<Dialog
						open={showTerms}
						onClose={() => setShowTerms(false)}
					>
						<Terms handleClose={() => setShowTerms(false)} />
					</Dialog>
				</Box>
			</Stack>
			{/* </AuthProvider> */}
		</ThemeProvider>
	);
}

export default App;
