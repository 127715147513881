import { useState, useEffect } from 'react';
//import { firestore } from 'firebase/app';
import {
	QuerySnapshot,
	query,
	CollectionReference,
	onSnapshot,
	QueryConstraint,
	DocumentReference,
	DocumentData,
	Query,
	Timestamp,
	doc,
	deleteDoc,
} from 'firebase/firestore';
import { collection, collectionGroup } from 'firebase/firestore';
import { db } from '../firebase';

interface CollectionData {
	id?: string;
	ref?: DocumentReference<CollectionData>;
}

//type CollectionSnapshot = QuerySnapshot<CollectionData>;

export function useCollection<T extends CollectionData>(
	collectionName: string,
	constraints?: QueryConstraint | Array<QueryConstraint>
) {
	const col = useCollectionInt<T>(collectionName, false, constraints);

	return col;
}

export function useCollectionGroup<T extends CollectionData>(
	collectionName: string,
	constraints?: QueryConstraint | Array<QueryConstraint>,
	converter?: (doc: T) => T
) {
	const col = useCollectionInt<T>(collectionName, true, constraints);

	if (converter) {
		col.docs = col.docs.map(converter);
	}

	return col;
}

function useCollectionInt<T extends CollectionData>(
	collectionName: string,
	isGroupCollectionQuery: boolean,
	constraints?: QueryConstraint | Array<QueryConstraint>
) {
	const [docs, setDocs] = useState<T[]>([]);
	const [loading, setLoading] = useState(true);
	const [qConstraints, setQueryConstraints] = useState<QueryConstraint | Array<QueryConstraint>>();

	useEffect(() => {
		const processSnapshot = (snapshot: QuerySnapshot) => {
			const updatedDocs = snapshot.docs.map((doc) => {
				const data = { ...doc.data() } as { [key: string]: any }; // Map<string, any>;

				function convertTimestampsToDates(doc: any) {
					if (doc instanceof Timestamp) {
						return doc.toDate();
					} else if (doc instanceof Object) {
						for (const [key, value] of Object.entries(doc)) {
							if (!(key === 'ref')) doc[key] = convertTimestampsToDates(value);
						}
					}
					return doc;
				}

				convertTimestampsToDates(data);

				return { ...(data as T), ref: doc.ref, id: doc.id };
			});

			setDocs(updatedDocs);
			setLoading(false);
		};

		let unsubscribe: () => void = () => {};

		let col: CollectionReference<DocumentData> | Query<DocumentData>;

		if (isGroupCollectionQuery) {
			col = collectionGroup(db, collectionName); //as CollectionReference<T>;
		} else {
			col = collection(db, collectionName); //as CollectionReference<T>;
		}

		//const collectionInstance = collection(db, collectionName) as CollectionReference<T>
		if (col) {
			setLoading(true);
			if (qConstraints) {
				if (qConstraints instanceof QueryConstraint) {
					unsubscribe = onSnapshot(query(col, qConstraints as QueryConstraint), processSnapshot);
				} else {
					unsubscribe = onSnapshot(query(col, ...(qConstraints as [QueryConstraint])), processSnapshot);
				}
			} else if (constraints) {
				if (constraints instanceof QueryConstraint) {
					unsubscribe = onSnapshot(query(col, constraints as QueryConstraint), processSnapshot);
				} else {
					unsubscribe = onSnapshot(query(col, ...(constraints as [QueryConstraint])), processSnapshot);
				}
			} else {
				unsubscribe = onSnapshot(col, processSnapshot);
			}
		}
		return unsubscribe;
	}, [qConstraints, collectionName, constraints, isGroupCollectionQuery]);

	const deleteDocument = async (docId: string): Promise<void> => {
		return new Promise<void>(async (resolve, reject) => {
			if (isGroupCollectionQuery) {
				reject('Cannot delete document from collection group');
			} else {
				await deleteDoc(doc(db, collectionName, docId));
				resolve();
			}
		});
	};

	return { docs, loading, setQueryConstraints, deleteDocument };
}
