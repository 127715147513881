// Import the functions you need from the SDKs you need
import {
    getAnalytics
} from "firebase/analytics";
import {
    initializeApp
} from "firebase/app";

import {
    connectFunctionsEmulator,
    getFunctions
} from "firebase/functions";

import {
    getApp
} from "firebase/app";
import {
    connectFirestoreEmulator,
    getFirestore
} from "firebase/firestore";

import {
    httpsCallable
} from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDybZBW9M7cPdC4DLpjJk-IJuuXGzV52So",
    authDomain: "caching-tickets.firebaseapp.com",
    projectId: "caching-tickets",
    storageBucket: "caching-tickets.appspot.com",
    messagingSenderId: "563607033858",
    appId: "1:563607033858:web:d1ada61c63cb113ae36f1b",
    measurementId: "G-35EBF0D6CC"
};

const useEmulators = false;
const useTestMerchant = false;


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore();
const functions_eur = getFunctions(getApp(), "europe-west1");


if (window.location.host === "localhost:3000" && useEmulators) {
    console.log("Using LOCAL EUMULATOR!");
    //connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, 'localhost', 8080);
    connectFunctionsEmulator(functions_eur, "localhost", 5001);
}

const initiatePayment = httpsCallable(functions_eur, 'initiatePayment');
const captureOrderPoll = httpsCallable(functions_eur, 'captureOrderPoll');




export {
    analytics, app, captureOrderPoll, db,
    initiatePayment, useEmulators, useTestMerchant
};
